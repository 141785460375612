// components/common/Footer.tsx
import React from 'react';
import './Footer.css';

export const Footer: React.FC = () => (
  <footer className="footer shadow-top">
    <div className="container mx-auto text-center">
      <p>&copy; 2024 RoundCodeBox. All rights reserved.</p>
    </div>
  </footer>
);
